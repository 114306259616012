export default function FormExtra() {
  const getOrgId = () => {
    const pathArray = window.location.pathname.split("/");
    return pathArray[1];
  };

  const forgotPasswordHref = `/${getOrgId()}/forgotPassword`;

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <input
          id="remember-me"
          name="remember-me"
          type="checkbox"
          className="h-4 w-4 text-blue-600 focus:ring-purple-500 border-gray-300 rounded"
        />
        <label
          htmlFor="remember-me"
          className="ml-2 block text-sm text-gray-900"
        >
          Remember me
        </label>
      </div>

      <div className="text-sm">
        <a
          href={forgotPasswordHref}
          className="font-medium text-blue-600 hover:text-purple-500"
        >
          Forgot your password?
        </a>
      </div>
    </div>
  );
}
