import React from "react";
import LoginHeader from "../components/login/LoginHeader.jsx";
import AdminLogin from "../components/admin/AdminLogin.jsx";

import "./css/login.css";

export default function AdminLoginPage() {
  //window.console.log("hallo: " + process.env.REACT_APP_CURRENT_ENVIRONMENT);
  //const serverEnv = process.env.REACT_APP_CURRENT_ENVIRONMENT;

  return (
    <div className="loginFormContainer">
      <LoginHeader
        heading="Admin Login"
        paragraph={`Enter admin-key to proceed!`}
        linkName=""
        linkUrl=""
      />
      <AdminLogin />
    </div>
  );
}
