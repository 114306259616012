import React, { useEffect, useRef } from "react";
import ChatMessage from "./ChatMessage.jsx";

const ChatMessageList = ({ messages, onMediaClick }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="message-list">
      {messages.map((message) => (
        <ChatMessage
          key={message._id}
          message={message}
          onMediaClick={onMediaClick}
        />
      ))}
      <div ref={messagesEndRef} />{" "}
      {/* Invisible element at the end of the messages */}
    </div>
  );
};

export default ChatMessageList;
