import React from "react";

import "./css/loginReferral.css";

const LoginReferral = ({ link }) => {
  return (
    <>
      <div className="referralContainer">
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Your session has expired
        </h1>
        <br />
        <a href={link} style={{ textDecoration: "underline", color: "blue" }}>
          Back to login
        </a>
      </div>
    </>
  );
};

export default LoginReferral;
