import React from "react";
import ForgotPassword from "../components/login/ForgotPassword.jsx";
import LoginHeader from "../components/login/LoginHeader.jsx";

import { useLocation } from "react-router-dom";

import "./css/login.css";

export default function ForgotPasswordPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgid = queryParams.get("orgid");

  return (
    <div className="loginFormContainer">
      <LoginHeader
        heading="Reset your password"
        paragraph="Back to "
        linkName="Login"
        linkUrl={`/${orgid}/login`}
      />
      <ForgotPassword />
    </div>
  );
}
