import { useState, useEffect } from "react";
import { signupFields } from "../../constants/LoginFormFields.jsx";
import AxiosInstance from "../../AxiosInstance.js";
import FormAction from "./LoginFormAction.jsx";
import Input from "./LoginInput.jsx";
import Toast from "../Toast.jsx";

const fields = signupFields;
let fieldsState = {};

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Signup() {
  const [signupState, setSignupState] = useState(fieldsState);
  const [toast, setToast] = useState({ show: false, message: "", color: "" });
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");
  const id = queryParams.get("id");
  const firstName = queryParams.get("firstname");
  const lastName = queryParams.get("lastname");

  useEffect(() => {
    if (email) {
      setSignupState((prevState) => ({
        ...prevState,
        email: email,
      }));
    }
    if (firstName && lastName) {
      setSignupState((prevState) => ({
        ...prevState,
        firstname: firstName,
        lastname: lastName,
      }));
    }
  }, [email, firstName, lastName]);

  const handleChange = (e) => {
    setSignupState({ ...signupState, [e.target.id]: e.target.value });
  };

  const getOrgId = () => {
    const pathArray = window.location.pathname.split("/"); // Split the path by '/'
    return pathArray[1];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const orgId = getOrgId();
    const url = `/api/signup`;
    const reqBody = {
      orgId: orgId,
      id: id,
      signupState,
    };

    try {
      const response = await AxiosInstance.post(url, reqBody);
      const data = response.data;
      if (response.status === 200 || response.status === 201) {
        console.log("Signup Successful:", data);
        setToast({ show: true, message: "Signup Successful!", color: "green" });

        // Delay 2 seconds and forward
        setTimeout(() => {
          window.location.href = `/${getOrgId()}/login`; // Adjust the URL pattern as needed
        }, 2000);
      } else {
        console.error("Signup Failed:", data.message);
        setToast({
          show: true,
          message: `Signup Failed: ${data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setToast({
        show: true,
        message: `Internal Error.`,
        color: "red",
      });
    }
  };

  const dismissToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <div>
      <form className="mt-8 space-y-2" onSubmit={handleSubmit}>
        <div className="">
          {fields.map((field) => (
            <Input
              key={field.id}
              handleChange={handleChange}
              value={signupState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
            />
          ))}
          <FormAction text="Signup" />
        </div>
      </form>
      {toast.show && (
        <Toast
          message={toast.message}
          color={toast.color}
          duration={3000}
          onDismiss={dismissToast}
        />
      )}
    </div>
  );
}
