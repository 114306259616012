import React, { createContext, useContext, useState, useEffect } from "react";
import AxiosInstance from "./AxiosInstance";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({ isLoggedIn: false });
  const orgId = window.location.pathname.split("/")[1];

  useEffect(() => {
    const checkSession = async () => {
      try {
        setAuthState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));

        const { data } = await AxiosInstance.post("/api/session", {
          currentOrgId: orgId,
        });

        if (data.isLoggedIn) {
          setAuthState((prevState) => ({
            ...prevState,
            isLoggedIn: true,
            isLoading: false, // Update isLoading here
            user: {
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              orgId: data.user.orgId,
              orgName: data.user.orgName,
              email: data.user.email,
              role: data.user.role,
            },
          }));
        } else {
          logout();
        }
      } catch (error) {
        logout();
        console.error("Session check failed:", error);
      } finally {
        setAuthState((prevState) => ({ ...prevState, isLoading: false })); // Ensure isLoading is set to false even if an error occurs
      }
    };

    checkSession();
  }, [orgId]);

  const logout = async () => {
    try {
      // Call the backend to destroy the session
      await AxiosInstance.post("/api/logout");
      setAuthState({ isLoggedIn: false, isLoading: false }); // Update this line
    } catch (error) {
      console.error("Logout failed:", error);
      setAuthState((prevState) => ({ ...prevState, isLoading: false })); // Ensure isLoading is set to false
    }
  };

  return (
    <AuthContext.Provider
      value={{ userSession: authState, setUserSession: setAuthState, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
