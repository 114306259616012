import React, { useState, useEffect } from "react";
import AxiosInstance from "../../AxiosInstance.js";
import { useAuth } from "../../AuthContext.js";
import { loginFields } from "../../constants/LoginFormFields.jsx";
import FormAction from "./LoginFormAction.jsx";
import FormExtra from "./LoginFormExtra.jsx";
import Input from "./LoginInput.jsx";
import Toast from "../Toast.jsx";

const fields = loginFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Login() {
  const [loginState, setLoginState] = useState(fieldsState);
  const [toast, setToast] = useState({
    show: false,
    message: "",
    color: "",
    duration: 6,
  });

  const { setUserSession } = useAuth(); // Use the login function from context

  // // Redirect if already logged in
  // useEffect(() => {
  //   if (userSession.isLoggedIn) {
  //     const orgId = getOrgId();
  //     navigate(`/${orgId}/startlist`);
  //   }
  // }, [userSession.isLoggedIn, navigate]);

  // Initialize loginState with loginFields
  useEffect(() => {
    const initialState = {};
    loginFields.forEach((field) => {
      initialState[field.id] = "";
    });
    setLoginState(initialState);
  }, []);

  const getOrgId = () => {
    const pathArray = window.location.pathname.split("/"); // Split the path by '/'
    return pathArray[1];
  };

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const orgId = getOrgId();
    const url = `/api/login`;

    const requestBody = {
      ...loginState,
      orgid: orgId,
    };

    try {
      const response = await AxiosInstance.post(url, requestBody);
      const data = response.data;

      if (response.status === 200) {
        console.log("Login Successful:", data);
        setUserSession(data.user); // Update auth state using the context
        setToast({ show: true, message: "Login Successful!", color: "green" });
        window.location.href = `/${orgId}/startlist`;
      } else {
        console.error("Login Failed:", data.message);
        setToast({
          show: true,
          message: `Login Failed: ${data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setToast({
        show: true,
        message: `Internal Error: ${
          error.response?.data?.message || error.message
        }`,
        color: "red",
      });
    }
  };

  const dismissToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <div>
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="-space-y-px">
          {fields.map((field) => (
            <Input
              key={field.id}
              handleChange={handleChange}
              value={loginState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
            />
          ))}
        </div>

        <FormExtra />
        <FormAction text="Login" />
      </form>
      {toast.show && (
        <Toast
          message={toast.message}
          color={toast.color}
          duration={3000}
          onDismiss={dismissToast}
        />
      )}
    </div>
  );
}
