import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext.js";
import AxiosInstance from "../AxiosInstance.js";
import TopNavigation from "../components/TopNavigation.jsx";
import SideNavigation from "../components/SideNavigation.jsx";
import Loading from "../components/Loading.jsx";
import TableAgents from "../components/tables/TableAgents.jsx";
import LoginReferral from "./LoginReferral.jsx";
import Toast from "../components/Toast.jsx";

import "./css/tables.css";
import "./css/admin.css";

const ManagementPage = () => {
  const orgid = window.location.pathname.split("/")[1];
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [newAgentEmail, setNewAgentEmail] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [agents, setAgents] = useState([]);
  const [rights, setRights] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "", color: "" });
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");

  const { userSession } = useAuth();

  const CHARACTER_LIMIT_CUSTOM_INSTRUCTIONS = 10000; // for custom instructions
  const CHARACTER_LIMIT_WELCOME_MESSAGE = 4096; // for welcome message

  useEffect(() => {
    if (userSession.isLoggedIn) {
      const fetchSettings = async () => {
        try {
          const response = await AxiosInstance.get(`/api/company/get/settings`);
          if (response.status === 200) {
            setWelcomeMessage(response.data.companySettings.welcomeMessage);
            setSystemPrompt(response.data.companySettings.systemPrompt);
          }
        } catch (error) {
          console.error("Failed to fetch settings: ", error);
        }
      };

      const fetchData = async () => {
        try {
          const response = await AxiosInstance.get(`/api/company/get/agents`);

          if (response.data.agents) {
            const tableData = response.data.agents.map((agent) => ({
              agent: `${agent.firstName} ${agent.lastName} <${agent.email}>`,
              totalOpen: "N/A",
              openConversations: agent,
              status: agent.status,
            }));

            setAgents(tableData);
            setRights(true);
            console.log(response.data);
          } else {
            console.log(response.data.message);
            setToast({
              show: true,
              message: `Failed: ${response.data.message}!`,
              color: "red",
            });
            if (response.status === 209) {
              setRights(false);
            } else {
              setRights(true);
            }
          }
        } catch (error) {
          console.error("Failed to fetch data: ", error);
          setToast({
            show: true,
            message: "Failed to fetch data!",
            color: "red",
          });
        }
      };

      fetchData();
      fetchSettings();
    }
  }, [userSession.isLoggedIn, orgid, refreshKey]);

  const handleSendInvite = async (agentEmail) => {
    if (userSession.isLoggedIn) {
      const requestBody = { email: agentEmail };
      const sendInvite = async () => {
        try {
          const response = await AxiosInstance.post(
            `/api/company/agent/invite`,
            requestBody
          );

          if (response.status === 201) {
            console.log("Successfully invited agent");
            setToast({
              show: true,
              message: "Successfully sent the invite!",
              color: "green",
            });
          } else {
            console.log("Failed: ", response.data.message);
            setToast({
              show: true,
              message: `Failed: ${response.data.message}`,
              color: "red",
            });
          }
        } catch (error) {
          console.error("Failed to send invite: ", error);
          setToast({
            show: true,
            message: "Failed to send invite!",
            color: "red",
          });
        }
      };

      sendInvite();
    }
  };

  const handleSystemPromptChange = (e) => {
    const input = e.target.value;
    if (input.length <= CHARACTER_LIMIT_CUSTOM_INSTRUCTIONS) {
      setSystemPrompt(input);
    }
  };

  const handleWelcomeMessageChange = (e) => {
    const input = e.target.value;
    if (input.length <= CHARACTER_LIMIT_WELCOME_MESSAGE) {
      setWelcomeMessage(input);
    }
  };

  const handleUpdateSettings = async () => {
    console.log("Updating company settings!" + welcomeMessage);

    try {
      const response = await AxiosInstance.post("/api/company/set/settings", {
        welcomeMessage,
        systemPrompt,
      });

      if (response.status === 200) {
        setToast({
          show: true,
          message: "Settings updated successfully!",
          color: "green",
        });
      } else {
        setToast({
          show: true,
          message: `Failed to update settings: ${response.data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Failed to update settings: ", error);
      setToast({
        show: true,
        message: "Failed to update settings!",
        color: "red",
      });
    }
  };

  const handleManagement = async (agentEmail, newStatus) => {
    try {
      const response = await AxiosInstance.post(
        "/api/company/agent/update/status",
        {
          agentEmail: agentEmail,
          newStatus: newStatus,
        }
      );

      if (response.status === 200) {
        setRefreshKey((oldKey) => oldKey + 1);
        setToast({
          show: true,
          message: `Success!`,
          color: "green",
        });
      } else {
        console.error("Error changing status:", response.data.message);
        setToast({
          show: true,
          message: `Error: ${response.data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error changing status:", error);
      setToast({
        show: true,
        message: `Internal Error`,
        color: "red",
      });
    }
  };

  if (userSession.isLoading || !rights) {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (!userSession.isLoggedIn) {
    return (
      <>
        <LoginReferral link={`/${orgid}/login`} />
      </>
    );
  }

  const dismissToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <>
      <TopNavigation
        userName={userSession.user.firstName || "User"}
        userCompany={userSession.user.orgName}
        language="EN"
      />
      <div className="layout-container">
        <SideNavigation
          links={[{ name: "Back", target: `/${orgid}/startlist` }]}
        />
        <div className="content-container">
          <div className="table-container">
            <h1 style={{ marginBottom: "2rem", fontSize: "2.4rem" }}>
              Manage Agents
            </h1>
            <TableAgents data={agents} onManaged={handleManagement} />
          </div>
          <div className="management-container">
            <div className="settings-container">
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 className="settings-header">
                    Mona's Custom Instructions
                  </h3>
                  <h3 className="settings-header">
                    {systemPrompt.length}/{CHARACTER_LIMIT_CUSTOM_INSTRUCTIONS}
                  </h3>
                </div>
                <textarea
                  className="textarea"
                  placeholder="Mona's custom instructions..."
                  value={systemPrompt}
                  onChange={(e) => handleSystemPromptChange(e)}
                  maxLength={CHARACTER_LIMIT_CUSTOM_INSTRUCTIONS}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 className="settings-header">Default Welcome Message</h3>
                  <h3 className="settings-header">
                    {welcomeMessage.length}/{CHARACTER_LIMIT_WELCOME_MESSAGE}
                  </h3>
                </div>
                <textarea
                  className="textarea"
                  placeholder="Default welcome message for new support requests..."
                  value={welcomeMessage}
                  onChange={(e) => handleWelcomeMessageChange(e)}
                  maxLength={CHARACTER_LIMIT_WELCOME_MESSAGE}
                />
              </div>
              <button className="save" onClick={handleUpdateSettings}>
                Save Settings
              </button>
            </div>
            <div className="invitation-container">
              <button
                className="open"
                onClick={() => setShowInviteForm(!showInviteForm)}
              >
                {showInviteForm ? "Close" : "Invite New Agent"}
              </button>

              {showInviteForm && (
                <div className="new-custom-form">
                  <input
                    type="text"
                    placeholder="Company Name"
                    value={userSession.user.orgName}
                    disabled
                  />
                  <input
                    type="email"
                    placeholder="Agent Email"
                    value={newAgentEmail}
                    onChange={(e) => setNewAgentEmail(e.target.value)}
                  />
                  <button
                    onClick={() => {
                      handleSendInvite(newAgentEmail);
                    }}
                  >
                    Send Invite
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {toast.show && (
        <Toast
          message={toast.message}
          color={toast.color}
          duration={3000}
          onDismiss={dismissToast}
        />
      )}
    </>
  );
};

export default ManagementPage;
