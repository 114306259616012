import React, { useEffect } from "react";
import "./css/toast.css"; // You'll need to create this CSS file

const Toast = ({ message, color, duration, onDismiss }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onDismiss]);

  return (
    <div className="toast" style={{ backgroundColor: color }}>
      {message}
    </div>
  );
};

export default Toast;
