import React from "react";
import Table from "./Table";

const TableCompanyManagement = ({
  businessAccounts,
  setShowStatusPopup,
  setSelectedCustomerId,
  setStatusPopupPosition,
}) => {
  const columns = [
    { header: "Company Name", accessor: "companyName" },
    {
      header: "Customer ID",
      accessor: "customerId",
      cellRenderer: (row) => (
        <button
          style={{ textDecoration: "underline" }}
          onClick={(e) => {
            window.open(`${window.origin}/${row.customerId}/login`, "_blank");
          }}
        >
          {row.customerId}
        </button>
      ),
    },
    { header: "Status", accessor: "status" },
    {
      header: "Action",
      accessor: "action",
      cellRenderer: (row) => (
        <button
          style={{ textDecoration: "underline" }}
          onClick={(e) => {
            const buttonRect = e.target.getBoundingClientRect();
            setSelectedCustomerId(row.customerId);
            setStatusPopupPosition({
              top: buttonRect.top,
              left: buttonRect.right,
            });
            setShowStatusPopup(true);
          }}
        >
          Change Status
        </button>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={businessAccounts}
        header="Business Accounts"
      />
    </div>
  );
};

export default TableCompanyManagement;
