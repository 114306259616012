import React from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";

import "./css/navigation.css";

const TopNavigation = ({ userName, userCompany, language }) => {
  const { userSession, logout } = useAuth();
  const navigate = useNavigate();
  const orgId = window.location.pathname.split("/")[1];
  const name = userSession.user.role === "admin" ? `${userName} ⭐` : userName;

  const handleLogout = async (e) => {
    e.preventDefault();
    navigate(`/${orgId}/login`);
    await logout(); // Make sure to await the logout if it's asynchronous
  };

  return (
    <div className="stickyContainer">
      <header className="header">
        <div className="header-container">
          <div className="flex items-center space-x-20">
            <img
              src={window.location.origin + "/logo_nobg.png"}
              alt="Monamy Logo"
              className="logo"
            />
            {/* <div className="ml-10">
              {links.map((link, index) => (
                <div key={index}>
                  <a href={link.target} className="nav-item">
                    {link.name}
                  </a>
                </div>
              ))}
            </div> */}
          </div>
          <div className="flex items-center space-x-40">
            <span className="user-info">
              Logged in as: <strong>{name}</strong>
              <br />({userCompany})
            </span>
            <span className="user-info">
              <a href="#" className="link">
                Language: {language}
              </a>
              <br />
              <a
                href="/"
                onClick={handleLogout}
                className="link"
                style={{ cursor: "pointer" }}
              >
                Logout
              </a>
            </span>
          </div>
        </div>
      </header>
    </div>
  );
};

export default TopNavigation;
