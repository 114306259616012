import Header from "../components/login/LoginHeader.jsx";
import Signup from "../components/login/Signup.jsx";

export default function ResetPage() {
  const firstPathSegment = window.location.pathname.split("/")[1];

  return (
    <div className="loginFormContainer">
      <Header
        heading="Update your account"
        paragraph="Back to "
        linkName="Login"
        linkUrl={`/${firstPathSegment}/login`}
      />
      <Signup />
    </div>
  );
}
