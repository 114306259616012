import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../AuthContext.js";
import AxiosInstance from "../AxiosInstance.js";
import ChatHeader from "../components/chat/ChatHeader.jsx";
import Slider from "../components/chat/ChatMediaSlider.jsx";
import ChatMessageList from "../components/chat/ChatMessageList.jsx";
import ChatMessageInput from "../components/chat/ChatMessageInput.jsx";
import ChatControls from "../components/chat/ChatControls.jsx";
import TopNavigation from "../components/TopNavigation.jsx";
import SideNavigation from "../components/SideNavigation.jsx";
import LoginReferral from "./LoginReferral.jsx";
import Loading from "../components/Loading.jsx";
import Toast from "../components/Toast.jsx";

import "./css/chat.css";

const ChatPage = () => {
  const eventSourceRef = useRef(null);
  const [conversation, setConversation] = useState(null);
  const [currentInput, setCurrentInput] = useState("");
  const [toast, setToast] = useState({ show: false, message: "", color: "" });
  const [slideShow, setSlideShow] = useState({
    show: false,
    initialMediaId: "",
  });
  const [searchParams] = useSearchParams();
  const [mediaMessages, setMediaMessages] = useState([]);
  const { userSession } = useAuth();

  const orgId = window.location.pathname.split("/")[1];
  const currentPhoneNumber = searchParams.get("phoneNumber");

  useEffect(() => {
    if (userSession.isLoggedIn) {
      const fetchConversation = async () => {
        try {
          console.log(currentPhoneNumber);
          const response = await AxiosInstance.get(
            `/api/conversations/get/single?phoneNumber=${encodeURIComponent(
              currentPhoneNumber
            )}`
          );

          if (response.status === 200) {
            console.log("Chat loaded successfully!");
            setConversation(response.data);
          } else {
            console.log(`Failed: ${response.data.message}`);
            setToast({
              show: true,
              message: `Failed: ${response.data.message}`,
              color: "red",
            });
          }
        } catch (error) {
          console.log(`Error: ${error}`);
          setToast({
            show: true,
            message: `Error: ${error}`,
            color: "red",
          });
        }
      };

      const listenForUpdates = () => {
        if (eventSourceRef.current) {
          eventSourceRef.current.close(); // Close existing connection if it exists
        }

        console.log(process.env.REACT_APP_BACKEND_URL);
        const eventSourceUrl = `${
          process.env.REACT_APP_BACKEND_URL
        }/api/conversations/single/updates?phoneNumber=${encodeURIComponent(
          currentPhoneNumber
        )}`;

        eventSourceRef.current = new EventSource(eventSourceUrl, {
          withCredentials: true,
        });

        eventSourceRef.current.onmessage = (event) => {
          const { newMessages, statusChanges } = JSON.parse(event.data);
          console.log("New messages:", newMessages);
          console.log("Status changes:", statusChanges);

          setConversation((prevConversation) => {
            // Update messages with new messages
            let updatedMessages = [
              ...prevConversation.messages,
              ...newMessages,
            ];

            // Update messages with status changes
            statusChanges.forEach((statusChange) => {
              const messageIndex = updatedMessages.findIndex(
                (msg) => msg._id === statusChange.id
              );
              if (messageIndex !== -1) {
                updatedMessages[messageIndex].status = statusChange.status;
              }
            });

            return {
              ...prevConversation,
              messages: updatedMessages,
            };
          });
        };

        eventSourceRef.current.onerror = (error) => {
          console.error("EventSource failed:", error);
          setToast({
            show: true,
            message: "A connection error occurred. Please try again.",
            color: "red",
          });
        };
      };

      fetchConversation().then(listenForUpdates);
      fetchMediaMessages();

      const handleEscape = (event) => {
        if (event.key === "Escape") {
          setSlideShow({ ...slideShow, show: false });
        }
      };

      // Add event listener
      window.addEventListener("keydown", handleEscape);

      return () => {
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
          eventSourceRef.current = null; // Reset the ref after closing
        }
        window.removeEventListener("keydown", handleEscape);
      };
    }
  }, [currentPhoneNumber, userSession.isLoggedIn]);

  const toggleAutoResponse = () => {
    console.log("Toggled auto response!");
  };

  const requestSuggestion = async (withPrompt) => {
    try {
      const response = await AxiosInstance.get(`/api/ai/get/suggestion`, {
        params: withPrompt
          ? { phoneNumber: conversation.clientNumber, prompt: currentInput }
          : { phoneNumber: conversation.clientNumber },
      });

      if (response.status === 200) {
        console.log("Suggestion: ", response.data.suggestion);

        // Insert text into inputfield here
        setCurrentInput(response.data.suggestion);
      } else {
        console.log(`Failed: ${response.data.message}`);
        setToast({
          show: true,
          message: `Failed: ${response.data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error fetching suggestion:", error);
      setToast({
        show: true,
        message: `Error getting suggestion: ${error}`,
        color: "red",
      });
    }
  };

  const fetchMediaMessages = async () => {
    try {
      const response = await AxiosInstance.get(
        `/api/media/conversation?phoneNumber=${encodeURIComponent(
          currentPhoneNumber
        )}`
      );

      if (response.status === 200) {
        setMediaMessages(response.data);
      } else {
        setToast({
          show: true,
          message: `Failed to load media messages: ${response.data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      setToast({
        show: true,
        message: `Error fetching media messages: ${error}`,
        color: "red",
      });
    }
  };

  const handleMediaClick = (mediaId) => {
    console.log(mediaId);
    setSlideShow({ show: true, initialMediaId: mediaId });
  };

  const handleTextChange = (text) => {
    setCurrentInput(text);
  };

  const handleSendMessage = async (messageInput) => {
    try {
      const reqBody = {
        phoneNumber: currentPhoneNumber,
        textMessage: messageInput,
      };
      const response = await AxiosInstance.post("/api/whatsapp/send", reqBody);

      if (response.status === 200) {
        // animate message sent
        setCurrentInput("");
        setToast({
          show: true,
          message: `Sent Successfully!`,
          color: "green",
        });
      } else {
        console.log(response.data.message);
        setToast({
          show: true,
          message: `Failed to send: ${response.data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.log(error);
      setToast({
        show: true,
        message: `Error: ${error}`,
        color: "red",
      });
    }
  };

  if (userSession.isLoading || (!conversation && userSession.isLoggedIn)) {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (!userSession.isLoggedIn) {
    return (
      <>
        <LoginReferral link={`/${orgId}/login`} />
      </>
    );
  }

  const dismissToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <>
      {slideShow.show ? (
        <Slider
          mediaMessages={mediaMessages}
          initialMediaId={slideShow.initialMediaId}
        />
      ) : (
        <>
          <TopNavigation
            userName={userSession.user.firstName}
            userCompany={userSession.user.orgName}
            language="EN"
          />
          <div className="layout-container">
            <SideNavigation
              links={[
                {
                  name: "Back To My Conversations",
                  target: `/${orgId}/agentslist`,
                },
              ]}
            />
            <div className="content-container">
              <div className="chat-container">
                <ChatHeader
                  clientNumber={currentPhoneNumber.substring(
                    currentPhoneNumber.indexOf("+")
                  )}
                />
                <ChatMessageList
                  messages={conversation.messages}
                  onMediaClick={handleMediaClick}
                />
                <ChatMessageInput
                  text={currentInput}
                  onTextChange={handleTextChange}
                  onSendMessage={handleSendMessage}
                />
                <ChatControls
                  autoResponseEnabled={conversation.autoResponseByAIEnabled}
                  onToggleAutoResponse={toggleAutoResponse}
                  onSuggestionRequested={requestSuggestion}
                  onSuggestionRequestedWithPrompt={() =>
                    requestSuggestion(true)
                  }
                />
              </div>
            </div>
          </div>
          {toast.show && (
            <Toast
              message={toast.message}
              color={toast.color}
              duration={3000}
              onDismiss={dismissToast}
            />
          )}
        </>
      )}
    </>
  );
};

export default ChatPage;
