import { formatDistanceToNow, parseISO } from "date-fns";

const formatDate = (dateString) => {
  const date = parseISO(dateString);
  const formattedDate = formatDistanceToNow(date, { addSuffix: true });
  return formattedDate;
};

const cropMessage = (message, maxLength = 50) => {
  return message.length > maxLength
    ? message.substring(0, maxLength) + "..."
    : message;
};

export { formatDate, cropMessage };
