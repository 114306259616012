import React from "react";
import Table from "./Table.jsx";

// Renderer function for action buttons
const renderActionCell = (row, actionType) => (
  <button
    style={{ textDecoration: "underline" }}
    onClick={() => handleActionClick(row)}
  >
    {actionType}
  </button>
);

const handleActionClick = (row) => {
  // Handle the click event here based on the action type
};

const TableAgents = ({ data, onManaged }) => {
  const columns = [
    { header: "Agents", accessor: "agent" },
    { header: "Total (Open)", accessor: "totalOpen" },
    {
      header: "Action",
      accessor: "openConversations",
      cellRenderer: (row) => renderActionCell(row, "Open Conversations"),
    },
    {
      header: "Manage Account",
      accessor: "status",
      cellRenderer: (row) => (
        <button
          style={{
            textDecoration: "underline",
            backgroundColor: row.status === "active" ? "red" : "green",
            color: "white",
            border: "none",
            borderRadius: "5px",
            padding: "5px 10px",
          }}
          onClick={(e) => {
            const emailMatch = row.agent.match(/<([^>]+)>/);

            if (emailMatch && emailMatch[1]) {
              onManaged(
                emailMatch[1],
                row.status === "active" ? "inactive" : "active"
              );
            } else {
              console.error("No email found in agent string");
              return;
            }
          }}
        >
          {row.status === "active" ? "Disable Account" : "Enable Account"}
        </button>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
      header={"LIST OF AGENTS - ONLY FOR ADMINS"}
    />
  );
};

export default TableAgents;
