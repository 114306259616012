import React, { useState } from "react";

import "./css/statusPopup.css";

export const StatusPopup = ({ id, onClose, onSave, position }) => {
  const [status, setStatus] = useState("");

  const handleSave = () => {
    onSave(id, status);
    onClose();
  };

  const popupStyle = {
    position: "absolute",
    top: position?.top, // Use position passed as props
    left: position?.left, // Use position passed as props
    zIndex: 1000, // Ensure it's above other elements
    // Add other styling here as needed
  };

  return (
    <div className="status-popup" style={popupStyle}>
      <select value={status} onChange={(e) => setStatus(e.target.value)}>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>
      <button onClick={handleSave}>Save</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};
