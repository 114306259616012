import React, { useState } from "react";

const ChatControls = ({
  autoResponseEnabled,
  onToggleAutoResponse,
  onSuggestionRequested,
  onSuggestionRequestedWithPrompt,
  onSetReminder,
}) => {
  const [reminderDate, setReminderDate] = useState("");
  const [isRequestDisabled, setIsRequestDisabled] = useState(false);

  const handleSetReminder = () => {
    if (reminderDate) {
      onSetReminder(reminderDate);
      setReminderDate(""); // Clear the reminder date after setting
    }
  };

  const handleSuggestionRequested = () => {
    if (onSuggestionRequested) onSuggestionRequested();
    // Disable the button
    setIsRequestDisabled(true);
    // Set a timeout to re-enable the button after 30 seconds
    setTimeout(() => {
      setIsRequestDisabled(false);
    }, 5000); // 5 seconds
  };

  const handleSuggestionRequestedWithPrompt = () => {
    if (onSuggestionRequestedWithPrompt) onSuggestionRequestedWithPrompt();
    // Disable the button
    setIsRequestDisabled(true);
    // Set a timeout to re-enable the button after 30 seconds
    setTimeout(() => {
      setIsRequestDisabled(false);
    }, 5000); // 5 seconds
  };

  return (
    <div className="chat-controls">
      {/* <label className="auto-response-toggle">
        <input
          type="checkbox"
          checked={autoResponseEnabled}
          onChange={onToggleAutoResponse}
        />
        Enable Auto-Response by AI
      </label> */}
      {/* <div className="reminder-control">
        <input
          type="date"
          value={reminderDate}
          onChange={(e) => setReminderDate(e.target.value)}
        />
        <button className="set-reminder-button" onClick={handleSetReminder}>
          Set Alert
        </button>
      </div> */}
      <button
        className="chat-ai-button"
        onClick={handleSuggestionRequested}
        disabled={isRequestDisabled}
      >
        Ask Mona
      </button>
      <button
        className="chat-ai-button"
        onClick={handleSuggestionRequestedWithPrompt}
        disabled={isRequestDisabled}
      >
        Ask Mona With Prompt
      </button>
    </div>
  );
};

export default ChatControls;
