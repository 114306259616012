import React from "react";
import "../css/table.css";

const Table = ({ columns, data, header }) => {
  if (!data || data.length === 0) {
    return (
      <div>
        <h1
          style={{
            fontSize: "1.5rem",
            fontWeight: "300",
            marginBottom: "0.5rem",
          }}
        >
          {header}
        </h1>
        <h1
          style={{
            textAlign: "left",
            fontSize: "large",
            color: "grey",
            marginBottom: "2%",
          }}
        >
          No data found
        </h1>
      </div>
    );
  } else {
    return (
      <div>
        <h1
          style={{
            fontSize: "1.5rem",
            fontWeight: "300",
            marginBottom: "0.5rem",
          }}
        >
          {header}
        </h1>
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>
                    {column.cellRenderer
                      ? column.cellRenderer(row)
                      : typeof row[column.accessor] === "object"
                      ? JSON.stringify(row[column.accessor])
                      : row[column.accessor]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export default Table;
