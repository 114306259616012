import React from "react";

const MessageInput = ({ text, onTextChange, onSendMessage }) => {
  const handleSendMessage = () => {
    if (text.trim()) {
      onSendMessage(text);
      // Now the parent component is responsible for clearing the text
    }
  };

  return (
    <div className="message-input-container">
      <textarea
        className="message-input"
        value={text} // Use text from props
        onChange={(e) => onTextChange(e.target.value)} // Use onTextChange from props
        placeholder="Your message or prompt"
      />
      <button className="send-button" onClick={handleSendMessage}>
        Send
      </button>
    </div>
  );
};

export default MessageInput;
