import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL;
console.log("url " + url);

const AxiosInstance = axios.create({
  baseURL: url,
  withCredentials: true, // Sends cookies with each request
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // You can check for or refresh tokens here, for example
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default AxiosInstance;
