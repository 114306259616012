import { useState } from "react";
import { forgotPasswordFields } from "../../constants/LoginFormFields.jsx";
import AxiosInstance from "../../AxiosInstance.js";
import FormAction from "./LoginFormAction.jsx";
import Input from "./LoginInput.jsx";
import Toast from "../Toast.jsx";

const fields = forgotPasswordFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function ForgotPassword() {
  const [forgotPasswordState, setForgotPasswordState] = useState(fieldsState);
  const [toast, setToast] = useState({ show: false, message: "", color: "" });
  const orgId = window.location.pathname.split("/")[1];

  const handleChange = (e) => {
    setForgotPasswordState({
      ...forgotPasswordState,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Use environment variable or default to 'localhost'
    const url = `/api/forgotPassword`;
    const reqBody = {
      orgid: orgId,
      email: forgotPasswordState.email,
    };

    try {
      const response = await AxiosInstance.post(url, reqBody);
      const data = response.data;

      if (response.status === 200) {
        console.log("Request Successful:", data);
        setToast({
          show: true,
          message: `Request Success!`,
          color: "green",
        });
      } else {
        console.error("Request Failed:", data.message);
        setToast({
          show: true,
          message: `Request Failed: ${data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setToast({
        show: true,
        message: `Internal Error`,
        color: "red",
      });
    }

    console.log("Password reset request:", forgotPasswordState);
  };

  const dismissToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <div>
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="-space-y-px">
          {fields.map((field) => (
            <Input
              key={field.id}
              handleChange={handleChange}
              value={forgotPasswordState[field.id]}
              labelText={field.labelText}
              labelFor={field.labelFor}
              id={field.id}
              name={field.name}
              type={field.type}
              isRequired={field.isRequired}
              placeholder={field.placeholder}
            />
          ))}
        </div>

        <FormAction text="Request Password Reset" />
      </form>

      {toast.show && (
        <Toast
          message={toast.message}
          color={toast.color}
          duration={3000}
          onDismiss={dismissToast}
        />
      )}
    </div>
  );
}
