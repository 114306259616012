import React from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";

import "./css/navigation.css";

const SideNavigation = ({ links }) => {
  return (
    <nav className="side-navigation">
      <ul>
        {links?.map((link, index) => (
          <li
            className="nav-item"
            key={index}
            onClick={() => (window.location.href = link.target)}
          >
            {link.name}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SideNavigation;
