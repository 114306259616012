import React from "react";
import Table from "./Table.jsx";
import { cropMessage, formatDate } from "./TableUtilities.jsx";

const TableWaitingAgentResponse = ({ data, onActionClick }) => {
  const renderActionCell = (row) => (
    <button
      style={{ textDecoration: "underline" }}
      onClick={() => onActionClick(row)}
    >
      {row.phoneNumber.substring(row.phoneNumber.indexOf("+"))}
    </button>
  );

  const columns = [
    {
      header: "Phone Number",
      accessor: "phoneNumber",
      cellRenderer: (row) => renderActionCell(row),
    },
    {
      header: "Last Message",
      accessor: "lastMessage",
      cellRenderer: (row) => cropMessage(row.lastMessage),
    },
    {
      header: "Last Message By",
      accessor: "lastMessageBy",
    },
    {
      header: "Last Update",
      accessor: "lastUpdate",
      cellRenderer: (row) => formatDate(row.lastUpdate),
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
      header={"OPEN - CLIENT WAITING FOR OUR RESPONSE"}
    />
  );
};

export default TableWaitingAgentResponse;
