import React from "react";

const ChatHeader = ({ clientNumber }) => {
  return (
    <div className="chat-header">
      <h1>Conversation with {clientNumber}</h1>
      {/* Include other header elements such as the AI toggle switch */}
    </div>
  );
};

export default ChatHeader;
