import React from "react";
import { formatDistanceToNow, parseISO } from "date-fns";

const ChatMessage = ({ message, onMediaClick }) => {
  const getOrgId = () => {
    const pathArray = window.location.pathname.split("/"); // Split the path by '/'
    return pathArray[1];
  };

  const formatSenderName = (name) => {
    const nameParts = name.split(" ");
    if (nameParts.length > 1) {
      return `${nameParts[0]} ${nameParts[1][0]}.`;
    }
    return name;
  };

  const mediaUrl =
    message.messageType != "text"
      ? `${process.env.REACT_APP_BACKEND_URL}/api/media/${getOrgId()}/${
          message.mediaId
        }.${message.mediaFormat.split("/")[1]}`
      : "";

  const handleMediaClick = () => {
    if (onMediaClick && message.mediaId) {
      onMediaClick(message.mediaId);
    }
  };

  const renderMessageContent = () => {
    switch (message.messageType) {
      case "text":
        return (
          <div
            dangerouslySetInnerHTML={{ __html: message.text }}
          />
        );
      case "image":
        return (
          <img
            src={mediaUrl}
            alt="Sent Image"
            className="message-image"
            onClick={handleMediaClick}
          />
        );
      case "video":
        return (
          <video controls style={{ maxWidth: "50%", borderRadius: "14px" }}>
            <source src={mediaUrl} type={""} />
            Your browser does not support the video tag.
          </video>
        );
      case "audio":
        return (
          <audio controls>
            <source src={mediaUrl} type={"audio/ogg"} />
            Your browser does not support the audio element.
          </audio>
        );
      case "document":
        return (
          <div>
            <a
              href={mediaUrl}
              download
              style={{ textDecoration: "underline", color: "blue" }}
            >
              Document
            </a>
          </div>
        );
      case "application":
        return (
          <div>
            <a
              href={mediaUrl}
              download
              style={{ textDecoration: "underline", color: "blue" }}
            >
              Document
            </a>
          </div>
        );
      default:
        return <p>Media message</p>;
    }
  };

  const messageClass =
    message.senderType === "client" ? "message-client" : "message-agent";

  return (
    <div className={`message ${messageClass}`}>
      <div className="message-header">
        {messageClass === "message-client"
          ? "Client"
          : `Mona (by ${formatSenderName(message.senderName)}${
              message.senderType === "admin" ? " ⭐" : ""
            })`}
      </div>
      <div className="message-content">{renderMessageContent()}</div>
      <div className="message-timestamp">
        {`${formatDistanceToNow(parseISO(message.timestamp), {
          addSuffix: true,
        })} | ${message.status}`}
      </div>
    </div>
  );
};

export default ChatMessage;
