import React from "react";
import LoginHeader from "../components/login/LoginHeader.jsx";
import Login from "../components/login/Login.jsx";

import "./css/login.css";

export default function LoginPage() {
  const firstPathSegment = window.location.pathname.split("/")[1];

  return (
    <div className="loginFormContainer">
      <LoginHeader heading="Login to your account" />
      <Login />
    </div>
  );
}
