import { Slide } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";
import styles from "../css/slider.module.css";

export default function Slider(data) {
  const defaultIndex = data.mediaMessages.findIndex(
    (msg) => msg.mediaId === data.initialMediaId
  );

  console.log(defaultIndex);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return (
    <div className={styles.container}>
      <Slide
        autoplay={false}
        infinite={false}
        transitionDuration="300"
        defaultIndex={defaultIndex}
      >
        {data.mediaMessages.map((msg, index) => {
          return (
            <div
              className={styles.slide}
              key={`${process.env.REACT_APP_BACKEND_URL}/${msg.url}`}
            >
              <div
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/${msg.url})`,
                  backgroundSize: "auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  minWidth: "30%",
                  minHeight: "30%",
                }}
              >
                {msg.text && msg.text.length > 0 && (
                  <span>{truncateText(msg.text, 100)}</span>
                )}
              </div>
            </div>
          );
        })}
      </Slide>
    </div>
  );
}
