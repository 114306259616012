import React, { useState, useEffect } from "react";
import AxiosInstance from "../AxiosInstance.js";
import TableCompanyManagement from "../components/tables/TableCompanyManagement.jsx";
import LoginReferral from "./LoginReferral.jsx";
import { useAuth } from "../AuthContext.js";
import { StatusPopup } from "../components/StatusPopup.jsx";
import TopNavigation from "../components/TopNavigation.jsx";
import Toast from "../components/Toast.jsx";

import "./css/admin.css";

const AdminPage = () => {
  const { userSession } = useAuth();
  const [businessAccounts, setBusinessAccounts] = useState([]);
  const [showNewCompanyForm, setShowNewCompanyForm] = useState(false); // State for new company form visibility
  const [showStatusPopup, setShowStatusPopup] = useState(false); // State for status popup visibility
  const [statusPopupPosition, setStatusPopupPosition] = useState(null); // Dynamic popup positioning
  const [selectedCustomerId, setSelectedCustomerId] = useState(null); // Selected customer ID for status change
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newAdminEmail, setNewAdminEmail] = useState("");
  const [newCompanyNumber, setNewCompanyNumber] = useState("");
  const [messagingService, setMessagingService] = useState("WhatsApp");
  const [toast, setToast] = useState({ show: false, message: "", color: "" });

  useEffect(() => {
    const fetchBusinessAccounts = async () => {
      try {
        const response = await AxiosInstance.get("/api/admin/accounts");
        if (response.status === 200) {
          setBusinessAccounts(response.data);
        } else if (response.status === 204) {
          console.error("No accounts to fetch!", response.data.message);
          setToast({
            show: true,
            message: `No accounts found!`,
            color: "red",
          });
        } else {
          console.error(
            "Error fetching business accounts:",
            response.data.message
          );
          setToast({
            show: true,
            message: `${response.data.message}`,
            color: "red",
          });
        }
      } catch (error) {
        console.error("Error fetching business accounts:", error);
        setToast({
          show: true,
          message: `Error: ${error}`,
          color: "red",
        });
      }
    };

    fetchBusinessAccounts();
  }, []);

  const handleChangeStatus = async (customerId, status) => {
    try {
      const response = await AxiosInstance.post("/api/admin/manage", {
        customerId,
        status,
      });

      if (response.status === 200) {
        window.location.reload();
      } else {
        console.error("Error changing status:", response.data.message);
        setToast({
          show: true,
          message: `Error: ${response.data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error changing status:", error);
      setToast({
        show: true,
        message: `Internal Error`,
        color: "red",
      });
    }
  };

  const handleCreateCompany = async (
    companyName,
    adminEmail,
    companyNumber,
    communicationPreference
  ) => {
    try {
      const response = await AxiosInstance.post("/api/admin/create", {
        companyName,
        adminEmail,
        companyNumber,
        messagingService,
      });

      if (response.status === 201) {
        // Success
        window.location.reload();
      } else {
        setToast({
          show: true,
          message: `Error: ${response.data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error creating company:", error);
      setToast({
        show: true,
        message: `Internal Error`,
        color: "red",
      });
    }
  };

  const dismissToast = () => setToast({ ...toast, show: false });

  if (!userSession.isLoggedIn || userSession.user.role !== "superadmin") {
    return <LoginReferral link={`/admin/login`} />;
  }

  return (
    <div className="table-container">
      <TopNavigation
        userName="Admin"
        userCompany="Admin Console"
        language="EN"
      />

      <h1 style={{ fontSize: "3rem", marginBottom: "2rem" }}>
        Manage Business Accounts
      </h1>

      <TableCompanyManagement
        businessAccounts={businessAccounts}
        setShowStatusPopup={setShowStatusPopup}
        setSelectedCustomerId={setSelectedCustomerId}
        setStatusPopupPosition={setStatusPopupPosition}
      />

      <button
        className="open"
        onClick={() => setShowNewCompanyForm(!showNewCompanyForm)}
      >
        {showNewCompanyForm ? "Close" : "Add New Company"}
      </button>

      {showNewCompanyForm && (
        <div className="new-custom-form">
          <input
            type="text"
            placeholder="Company Name"
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Admin Email"
            value={newAdminEmail}
            onChange={(e) => setNewAdminEmail(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <input
              type="text"
              placeholder="Company Phone Number"
              value={newCompanyNumber}
              onChange={(e) => setNewCompanyNumber(e.target.value)}
            />
            <select
              value={messagingService}
              onChange={(e) => setMessagingService(e.target.value)}
            >
              {" "}
              <option value="WhatsApp">WhatsApp</option>
            </select>
          </div>
          <button
            onClick={() =>
              handleCreateCompany(
                newCompanyName,
                newAdminEmail,
                newCompanyNumber,
                messagingService
              )
            }
          >
            Create Company
          </button>
        </div>
      )}

      {showStatusPopup && (
        <StatusPopup
          id={selectedCustomerId}
          onClose={() => setShowStatusPopup(false)}
          onSave={handleChangeStatus}
          position={statusPopupPosition}
        />
      )}
      {toast.show && (
        <Toast
          message={toast.message}
          color={toast.color}
          duration={3000}
          onDismiss={dismissToast}
        />
      )}
    </div>
  );
};

export default AdminPage;
