import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext.js";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../AxiosInstance.js";
import TopNavigation from "../components/TopNavigation.jsx";
import SideNavigation from "../components/SideNavigation.jsx";
import Loading from "../components/Loading.jsx";
// import TableTodoConversations from "../components/tables/TableTodoConversations.jsx";
import TableWaitingAgentResponse from "../components/tables/TableWaitingAgentResponse.jsx";
import TableWaitingUserResponse from "../components/tables/TableWaitingUserResponse.jsx";
import LoginReferral from "./LoginReferral.jsx";
import Toast from "../components/Toast.jsx";

import "./css/tables.css";

const AgentsListPage = () => {
  const orgId = window.location.pathname.split("/")[1];
  // const [todo, setTodo] = useState([]);
  const [pending, setPending] = useState([]);
  const [open, setOpen] = useState([]);
  const [toast, setToast] = useState({ show: false, message: "", color: "" });
  const { userSession } = useAuth(); // Use the authentication context

  const navigate = useNavigate();

  useEffect(() => {
    if (userSession.isLoggedIn) {
      const fetchData = async () => {
        try {
          const [responseOpen, responsePending] = await Promise.all([
            AxiosInstance.get(`/api/conversations/get?status=open`),
            AxiosInstance.get(`/api/conversations/get?status=pending`),
          ]);

          if (responseOpen.status === 200) {
            setOpen(responseOpen.data);
          } else {
            console.error("Failed to fetch open convos: ", responseOpen.data);
          }

          if (responsePending.status === 200) {
            setPending(responsePending.data);
          } else {
            console.error(
              "Failed to fetch pending convos: ",
              responseOpen.data
            );
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setToast({
            show: true,
            message: "Failed to fetch data!",
            color: "red",
          });
        }
      };

      fetchData();
    }
  }, [userSession.isLoggedIn, orgId]); // Depend on isLoggedIn to re-fetch when the auth status changes

  const onOpenConversation = (row) => {
    navigate(
      `/${orgId}/chat?phoneNumber=${encodeURIComponent(row.phoneNumber)}`,
      {
        replace: true,
      }
    );
  };

  if (userSession.isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (!userSession.isLoggedIn) {
    return <LoginReferral link={`/${orgId}/login`} />;
  }

  const dismissToast = () => setToast({ ...toast, show: false });

  return (
    <>
      <TopNavigation
        userName={userSession.user.firstName || "User"} // Use user info from session or a placeholder
        userCompany={userSession.user.orgName}
        language="EN"
      />
      <div className="layout-container">
        <SideNavigation
          links={[
            {
              name: "Back To New Conversations",
              target: `/${orgId}/startlist`,
            },
          ]}
        />
        <div className="content-container">
          <div className="table-container">
            <h1 style={{ marginBottom: "2rem", fontSize: "2.4rem" }}>
              My Active WhatsApp Conversations
            </h1>
            {/* <TableTodoConversations data={todo} /> */}
            <TableWaitingAgentResponse // Open Convos
              data={open}
              onActionClick={onOpenConversation}
            />
            <TableWaitingUserResponse // Pending Convos
              data={pending}
              onActionClick={onOpenConversation}
            />
            {toast.show && (
              <Toast
                message={toast.message}
                color={toast.color}
                duration={3000}
                onDismiss={dismissToast}
              />
            )}
          </div>
        </div>
      </div>
      {toast.show && (
        <Toast
          message={toast.message}
          color={toast.color}
          duration={3000}
          onDismiss={dismissToast}
        />
      )}
    </>
  );
};

export default AgentsListPage;
