import React from "react";
import Table from "./Table.jsx";
import { cropMessage, formatDate } from "./TableUtilities.jsx";

const TableNewConversations = ({ data, onActionClick }) => {
  const renderActionCell = (row) => (
    <button
      style={{ textDecoration: "underline" }}
      onClick={() => onActionClick(row)}
    >
      Grab Conversation
    </button>
  );

  const columns = [
    {
      header: "Phone Number",
      accessor: "phoneNumber",
      cellRenderer: (row) =>
        row.phoneNumber.substring(row.phoneNumber.indexOf("+")),
    },
    {
      header: "Last Message",
      accessor: "lastMessage",
      cellRenderer: (row) => cropMessage(row.lastMessage),
    },
    {
      header: "Last Update",
      accessor: "lastUpdate",
      cellRenderer: (row) => formatDate(row.lastUpdate),
    },
    {
      header: "Action",
      accessor: "action",
      cellRenderer: (row) => renderActionCell(row),
    },
  ];

  return (
    <Table columns={columns} data={data} header={"NEW CONVERSATIONS - QUEUE"} />
  );
};

export default TableNewConversations;
