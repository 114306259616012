import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../AxiosInstance.js";
import { useAuth } from "../../AuthContext.js";
import FormAction from "../login/LoginFormAction.jsx";
import Input from "../login/LoginInput.jsx";
import Toast from "../Toast.jsx";

export default function AdminLogin() {
  const [password, setPassword] = useState("");
  const [toast, setToast] = useState({ show: false, message: "", color: "" });
  const navigate = useNavigate();
  const { userSession, setUserSession } = useAuth();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await AxiosInstance.post("/api/admin/login", {
        password,
      });
      const data = response.data;

      if (response.status === 200) {
        console.log("Admin Login Successful:", data);
        setUserSession({ isLoggedIn: true, user: data.user });
        setToast({
          show: true,
          message: data.message,
          color: "green",
        });
        navigate("/admin/overview");
      } else {
        console.error("Admin Login Failed:", data.message);
        setToast({
          show: true,
          message: `Admin Login Failed: ${data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setToast({
        show: true,
        message: `Internal Error: ${
          error.response?.data?.message || error.message
        }`,
        color: "red",
      });
    }
  };

  const dismissToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <div>
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="-space-y-px">
          <Input
            key="admin-password"
            handleChange={handleChange}
            value={password}
            labelText="Password"
            labelFor="admin-password"
            id="admin-password"
            name="password"
            type="password"
            isRequired={true}
            placeholder="Enter admin password"
          />
        </div>
        <FormAction text="Admin Login" />
      </form>
      {toast.show && (
        <Toast
          message={toast.message}
          color={toast.color}
          duration={3000}
          onDismiss={dismissToast}
        />
      )}
    </div>
  );
}
