const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
];

const forgotPasswordFields = [
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
];

const createCompanyFields = [
  {
    labelText: "Company Name",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "name",
    autoComplete: "name",
    isRequired: true,
    placeholder: "Your company's name",
  },
  {
    labelText: "Admin email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
];

const signupFields = [
  {
    labelText: "First name",
    labelFor: "firstname",
    id: "firstname",
    name: "firstname",
    type: "name",
    autoComplete: "first name",
    isRequired: true,
    placeholder: "First name",
  },
  {
    labelText: "Last name",
    labelFor: "lastname",
    id: "lastname",
    name: "lastname",
    type: "name",
    autoComplete: "last name",
    isRequired: true,
    placeholder: "Last name",
  },
  {
    labelText: "Email address",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirmpassword",
    id: "confirmpassword",
    name: "confirmpassword",
    type: "password",
    autoComplete: "confirmpassword",
    isRequired: true,
    placeholder: "Confirm Password",
  },
];

export { loginFields, signupFields, createCompanyFields, forgotPasswordFields };
