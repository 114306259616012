import React from "react";

const NotFound404 = () => {
  return (
    <div style={{ margin: "20%" }}>
      <h1 style={{ textAlign: "center" }}>404 Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound404;
