import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext.js";
import AxiosInstance from "../AxiosInstance.js";
import TopNavigation from "../components/TopNavigation.jsx";
import SideNavigation from "../components/SideNavigation.jsx";
import Loading from "../components/Loading.jsx";
import TableNewConversations from "../components/tables/TableNewConversations.jsx";
import LoginReferral from "./LoginReferral.jsx";
import Toast from "../components/Toast.jsx";

import "./css/tables.css";

const StartListPage = () => {
  const orgid = window.location.pathname.split("/")[1];
  const [newConversations, setNewConversations] = useState([]);
  const [toast, setToast] = useState({ show: false, message: "", color: "" });
  const [refreshKey, setRefreshKey] = useState(0);

  const { userSession } = useAuth();

  const fetchData = async () => {
    try {
      const response = await AxiosInstance.get(
        `/api/conversations/get?status=new`
      );

      if (response.status === 200) {
        setNewConversations(response.data);
        console.log(response.data);
      } else {
        console.error(`${response.data.message}`);
      }
    } catch (error) {
      console.error("Failed to fetch data: ", error);
      setToast({
        show: true,
        message: "Failed to fetch data!",
        color: "red",
      });
    }
  };

  useEffect(() => {
    if (userSession.isLoggedIn) {
      console.log("Fetching conversations...");
      fetchData();
    }
  }, [userSession.isLoggedIn, orgid, refreshKey]);

  const handleGrabConversation = async (row) => {
    const phoneNumber = row.phoneNumber;
    const newStatus = "open";

    try {
      const response = await AxiosInstance.post(
        "/api/conversations/update/status",
        {
          phoneNumber: phoneNumber,
          status: newStatus,
        }
      );

      if (response.status === 200) {
        setToast({
          show: true,
          message: "Success!",
          color: "green",
        });
        // Ensure fetchData is called after state update
        setRefreshKey((oldKey) => oldKey + 1);
      } else {
        console.log(response.data.message);
        setToast({
          show: true,
          message: `Failed: ${response.data.message}`,
          color: "red",
        });
      }
    } catch (error) {
      console.error("Failed to grab conversation:", error);
      setToast({
        show: true,
        message: `Error: ${error.message}`,
        color: "red",
      });
    }
  };

  if (userSession.isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (!userSession.isLoggedIn) {
    return (
      <>
        <LoginReferral link={`/${orgid}/login`} />
      </>
    );
  }

  const dismissToast = () => {
    setToast({ ...toast, show: false });
  };

  return (
    <>
      <TopNavigation
        userName={userSession.user.firstName || "User"}
        userCompany={userSession.user.orgName || "Company"}
        language="EN"
      />
      <div className="layout-container">
        <SideNavigation
          links={[
            { name: "Show My Conversations", target: `/${orgid}/agentslist` },
            userSession.user.role === "admin" && {
              name: `Management ⭐`,
              target: `/${orgid}/agentsmanagement`,
            },
          ].filter(Boolean)} // This filters out null or undefined entries
        />
        <div className="content-container">
          <div className="table-container">
            <h1 style={{ marginBottom: "2rem", fontSize: "2.4rem" }}>
              New Conversations
            </h1>
            <TableNewConversations
              data={newConversations}
              onActionClick={handleGrabConversation}
            />
          </div>
        </div>
      </div>
      {toast.show && (
        <Toast
          message={toast.message}
          color={toast.color}
          duration={3000}
          onDismiss={dismissToast}
        />
      )}
    </>
  );
};

export default StartListPage;
