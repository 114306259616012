import React from "react";
import "./css/loading.css"; // Make sure to import the CSS file

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading"></div>
      <div id="loading-text">loading</div>
    </div>
  );
};

export default Loading;
