import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { AuthProvider } from "./AuthContext";
import SuperAdminLoginPage from "./pages/SuperAdminLoginPage";
import SuperAdminPage from "./pages/SuperAdminPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ResetPage from "./pages/ResetPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import StartListPage from "./pages/StartListPage";
import AgentsListPage from "./pages/AgentsListPage";
import ManagementPage from "./pages/ManagementPage";
import ChatPage from "./pages/ChatPage";
import NotFound404 from "./pages/NotFound404";

function App() {
  return (
    <div className="app-container">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/admin/login" />} />
            <Route path="/admin" element={<Navigate to="/admin/login" />} />
            <Route path="/admin/login" element={<SuperAdminLoginPage />} />
            <Route path="/admin/overview" element={<SuperAdminPage />} />
            <Route path="/:companyName/login" element={<LoginPage />} />
            <Route path="/:companyName/signup" element={<SignupPage />} />
            <Route path="/:companyName/reset" element={<ResetPage />} />
            <Route
              path="/:companyName/forgotPassword"
              element={<ForgotPasswordPage />}
            />
            <Route path="/:companyName/startlist" element={<StartListPage />} />
            <Route
              path="/:companyName/agentslist"
              element={<AgentsListPage />}
            />
            <Route
              path="/:companyName/agentsmanagement"
              element={<ManagementPage />}
            />
            <Route path="/:companyName/chat" element={<ChatPage />} />
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
